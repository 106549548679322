/* Kolory */

$color-white:       #fff;

$color-black:       #25292f;
$color-black-dark:  #17181A;
$color-black-0:     #000;

$color-gray:        #f2f2f2;
$color-gray-light:  rgba(112,112,112,0.45);
$color-gray-dark: 	#707070;

$color-green-dark:  #395E0F;
$color-green:       #A4D500;
$color-green-light: #7DBF31;
$color-green-hover: #568D16;

/* Kolory tla */
$bg-main:                           $color-white;
$bg-second:                         $color-gray;
$bg-additional:                     $color-black;
$bg-photos:                         $color-black-0;

/* Kolory fontow */
$color-font-main:                   $color-black-0;
$color-font-second:                 $color-white;


/* Kolor tla przyciskow */

$bg-button-second:                  $color-black;
$bg-button-additional:              $color-white;

/* Kolor fonta w przyciskach */

$color-btn-second:                  $color-black;
$color-btn-additional:              $color-white;


/* Bordery do przyciskow */

$border-btn-second: 2px solid       $color-black;
$border-btn-additional: 2px solid   $color-white;


/* Wysokosc header/nav */
$height-header: 66px;

/* Box shadow dla przyciskow */
$boxshadow-btn: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

/* Gradienty do sliderow */
$gradient-footer-slider: linear-gradient(90deg, rgba(153,218,255,0) 0%, rgba(255,255,255,1) 100%);

/*Breakpointy */
$breakpoint-extra-mobile-max:     575.98px;
$breakpoint-mobile-min:           576px;
$breakpoint-mobile-max:           767.98px;
$breakpoint-tablet-min:           768px;
$breakpoint-tablet-max:           1024.98px;
$breakpoint-desktop-min:          1025px;
$breakpoint-desktop-max:          1199.98px;
$breakpoint-desktop-large-min:    1200px;
$breakpoint-desktop-large-max:    1279.98px;
$breakpoint-desktop-xxl-min:      1280px;


@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

@keyframes widthUp {
    0%      { width: 0%; }
    100%    { width: 100%; }
}
@keyframes heightUp {
    0%      { height: 0%; }
    100%    { height: 100%; }
}
@keyframes widthDown {
	0%		{ width: 100%; }
	100%	{ width: 0%; }
}
